<template>
  <a @click="$emit('child-click')" v-bind:class="addClassNameBtn" class="btnBsc-Black" v-html="text"></a>
</template>

<script>
export default {
  name  : 'Button',
  props : {
    text         : {
      type     : String,
      required : true
    },
    addClassNameBtn : {type : String}
  }
}
</script>

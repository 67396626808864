<template>
  <main id="main">
    <div class="wrap1160">
      <section id="guide">
          <h1><span>{{ $t("GUIDE_SELL_H1") }}</span></h1>
          <h2>
            <span class="highlight"
              >{{ $t("GUIDE_SELL_H2") }}</span
            >
          </h2>
          <ul class="step">
            <li class="step">
              <h3><em>STEP1</em>{{ $t("GUIDE_SELL_STEP1_TITLE1") }}</h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>
                      {{ $t("GUIDE_SELL_STEP1_TEXT1") }}<br />
                    </p>
                  </div>
                </div>
                <Button :text="$t('GUIDE_NEW_MEMBER_REGISTRATION')" :addClassNameBtn="null" @child-click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)" class="taC btn-signup"/>
              </div>
            </li>
            <li class="step">
              <h3><em>STEP2</em>{{ $t("GUIDE_SELL_STEP2_TITLE1") }}</h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>
                      {{ $t("GUIDE_SELL_STEP2_TEXT1") }}<br />
                      {{ $t("GUIDE_SELL_STEP2_TEXT2") }}
                    </p>
                    <p>
                      <a href="https://www.obm.jp/info.php?ID=4" target="_blank"
                        >{{ $t("INFORMATION_ON_REQUIRED_DOCUMENT") }}</a
                      >
                    </p>
                  </div>
                </div>
                <section id="entry-info">
                  <dl>
                    <dt>
                      <a href="https://www.obm.jp/info.php?ID=4" target="_blank"
                        >{{ $t("GUIDE_SELL_STEP2_TEXT3") }}</a
                      >{{ $t("GUIDE_SELL_STEP2_TEXT4") }}
                    </dt>
                    <dd>
                      <ol>
                        <li>
                          <a href="#modal-open-1">
                            <p class="entry-info-item">
                              <span>1</span>
                              {{ $t("GUIDE_SELL_STEP2_TEXT5") }}
                            </p>
                            <div class="pct">
                              <img
                                src="@/assets/img/guide/pct_guide_idcard.png"
                                alt=""
                              />
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#modal-open-2">
                            <p class="entry-info-item">
                              <span>2</span>
                              {{ $t("GUIDE_SELL_STEP2_TEXT6") }}
                            </p>
                            <div class="pct">
                              <img
                                src="@/assets/img/guide/pct_guide_document.png"
                                alt=""
                              />
                            </div>
                            <p class="kome entry-info-note">
                              {{ $t("GUIDE_SELL_STEP2_TEXT7") }}
                            </p>
                          </a>
                        </li>
                        <li>
                          <a href="#modal-open-3">
                            <p class="entry-info-item">
                              <span>3</span>
                              {{ $t("GUIDE_SELL_STEP2_TEXT8") }}
                            </p>
                            <div class="pct">
                              <img
                                src="@/assets/img/guide/pct_guide_picture.png"
                                alt=""
                              />
                            </div>
                            <p class="kome entry-info-note">
                              {{ $t("GUIDE_SELL_STEP2_TEXT9") }}
                            </p>
                          </a>
                        </li>
                      </ol>
                    </dd>
                  </dl>
                </section>
                <Button :text="$t('PURCHASE_ASSESSMENT_REQUEST')" :addClassNameBtn="null" @child-click="$routerGo($define.PAGE_NAMES.INSPECTION_REQUEST_PAGE_NAME)" class="taC btn-signup" />
                <div class="judge">
                  <div class="outline">
                    <div class="out-txt">
                      <p class="fontB txtColor">{{ $t("GUIDE_SELL_STEP2_TEXT10") }}</p>
                      <p class="mt20">
                        {{ $t("GUIDE_SELL_STEP2_TEXT11") }}<br />
                        {{ $t("GUIDE_SELL_STEP2_TEXT12") }}<br />
                      </p>
                    </div>
                    <div class="out-img">
                      <img
                        src="@/assets/img/guide/pct_guide_assessment.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="step">
              <h3><em>STEP3</em>{{ $t("GUIDE_SELL_STEP3_TITLE1") }}</h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>
                      <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">{{ $t("GUIDE_SELL_STEP3_TEXT1") }}</a
                      >{{ $t("GUIDE_SELL_STEP3_TEXT2") }}<br />
                      {{ $t("GUIDE_SELL_STEP3_TEXT3") }}<span class="highlight"
                        >{{ $t("GUIDE_SELL_STEP3_TEXT4") }}</span
                      >
                    </p>
                    <p class="assist-out-txt">{{ $t("GUIDE_SELL_STEP3_TEXT5") }}</p>
                  </div>
                </div>
                <a id="purch"></a>
                <ul id="tab_btn">
                  <li @click="select('1')" v-bind:class="{'active': show === '1'}" class="tab-auction">
                    <a href="#tab1"
                      >{{$t("GUIDE_SELL_TAB1_TITLE1")}}<br class="only_sp" />{{$t("GUIDE_SELL_TAB1_TITLE2")}}</a
                    >
                  </li>
                  <li @click="select('2')" v-bind:class="{'active': show === '2'}" class="tab-tender">
                    <a href="#tab2">{{$t("GUIDE_SELL_TAB2_TITLE1")}}<br class="only_sp">{{$t("GUIDE_SELL_TAB2_TITLE2")}}</a>
                  </li>
                </ul>
                <ul id="tab_info">
                  <li v-if="show === '1'" class="tab_i-auction">
                    <h4 class="step">
                      <a href="https://www.obm.jp/info.php?ID=4" target="_blank"
                        >{{ $t("GUIDE_SELL_TAB1_H4_1") }}</a
                      >{{ $t("GUIDE_SELL_TAB1_H4_1__2") }}
                    </h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span
                      ><span class="txt"
                        >{{ $t("GUIDE_SELL_TAB1_TEXT1") }}<br />
                        <a
                          href="https://www.obm.jp/info.php?ID=4"
                          target="_blank"
                          >{{ $t("INFORMATION_ON_REQUIRED_DOCUMENT") }}</a
                        ></span
                      >
                    </div>
                    <h4 class="step">{{ $t("GUIDE_SELL_TAB1_H4_2") }}</h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span>
                      <span class="txt"
                        >{{ $t("GUIDE_SELL_TAB1_TEXT2") }}</span
                      >
                    </div>
                    <h4 class="step">
                      <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)">{{ $t("GUIDE_SELL_TAB1_H4_3__1") }}</a
                      >{{ $t("GUIDE_SELL_TAB1_H4_3__2") }}
                    </h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span>
                      <span class="txt"
                        >{{ $t("GUIDE_SELL_TAB1_TEXT3") }}<br />
                        <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)"
                          >{{ $t("GUIDE_SHIPPING_METHOD") }}</a
                        ></span
                      >
                    </div>
                    <h4 class="step">{{ $t("GUIDE_SELL_TAB1_H4_4") }}</h4>
                    <div class="dli-arrow">
                      <span></span>
                      <span class="txt"
                        >{{ $t("GUIDE_SELL_TAB1_TEXT4") }}</span
                      >
                    </div>
                  </li>

                  <li v-else-if="show === '2'" class="tab_i-tender">
                    <h4>{{$t("GUIDE_SELL_TAB2_TEXT1")}}</h4>
                    <div class="schedule">
                      <div class="schedule_txt">
                        <p>
                          {{$t("GUIDE_SELL_TAB2_TEXT2")}}
                        </p>
                        <Button :text="$t('ABOUT_AUCTION_LISTING')" :addClassNameBtn="'btn-list'" @child-click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_PAGE_NAME)" class="schedule_btn"/>
                      </div>
                      <figure>
                        <img src="@/assets/img/guide/pct_guide_listing.png" alt="" />
                      </figure>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
    </div>
  </main>

</template>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
<style scoped lang="sass" src="@/assets/scss/guide.sass"></style>
<style scoped lang="sass" src="@/assets/scss/other.sass"></style>

<script>
import Button from '../../components/Guide/Parts/Button';

export default {
  data() {
    return {
      show : '1',
    }
  },
  components : {
    Button,
  },
  methods : {
    select(num) {
      this.show = num
    }
  }
}
</script>
